import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './App.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const VolleyballScoreApp = () => {
  const [scores, setScores] = useState([[0,0]]);
  const [currentFrame, setCurrentFrame] = useState(scores.length-1);
  const [names, setNames] = useState([
    "Typhoon",
    "Away"
  ]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [graphOpen, setGraphOpen] = useState(false);

  const handleScoreIncrement = (index) => {
    const newScores = scores.slice(0, currentFrame+1);
    const newFrame = newScores.at(-1).slice();
    newFrame[index]++;
    newScores.push(newFrame);
    setScores(newScores);
    setCurrentFrame(newScores.length-1);
  };

  const handleNameChange = (index, newName) => {
    const newNames = [...names];
    newNames[index] = newName;
    setNames(newNames);
  };

  const handleUndo = () => {
    if (currentFrame > 0) {
      setCurrentFrame(currentFrame-1);
    }
  };

  const handleRedo = () => {
    if (currentFrame < scores.length - 1) {
      setCurrentFrame(currentFrame+1);
    }
  };

  const handleResetScores = () => {
    setScores([[0, 0]]);
    setCurrentFrame(0);
    setSettingsOpen(false);
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleGraphOpen = () => {
    setGraphOpen(true);
  };
  
  const handleGraphClose = () => {
    setGraphOpen(false);
  };

  return (
    <div className="app-container">
      <div
        className="team-container team-a"
      >
        <input
          type="text"
          className="name"
          value={names[0]}
          onChange={(e) => handleNameChange(0, e.target.value)}
        />
        <div
          className="score"
          onClick={() => handleScoreIncrement(0)}>
          {scores[currentFrame][0]}
        </div>
      </div>
      <div
        className="team-container team-b"
      >
        <input
          type="text"
          className="name"
          value={names[1]}
          onChange={(e) => handleNameChange(1, e.target.value)}
        />
        <div
          className="score"
          onClick={() => handleScoreIncrement(1)}>
          {scores[currentFrame][1]}
        </div>
      </div>
      <div className="controls-container">
        <Button variant="contained" startIcon={<UndoIcon fontSize="large" />} onClick={handleUndo} disabled={scores.length===1} />
        <Button variant="contained" startIcon={<RedoIcon fontSize="large" />} onClick={handleRedo} disabled={scores.length===currentFrame+1} />
      </div>
      <div className="settings-container">
        <Button
          variant="contained"
          onClick={handleSettingsOpen}
          className="settings-button"
          startIcon={<SettingsIcon fontSize="large" />}
        />
        <Dialog open={settingsOpen} onClose={handleSettingsClose}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent>
            <Button variant="contained" onClick={handleResetScores}>
              Reset Scores
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSettingsClose}>Close</Button>
          </DialogActions>
        </Dialog>

      <Button
        variant="contained"
        onClick={handleGraphOpen}
        className="settings-button"
        startIcon={<ShowChartIcon fontSize="large" />}
      />
      <Dialog open={graphOpen} onClose={handleGraphClose} fullScreen>
        <DialogContent>
          <Line
            data={{
              labels: scores.map((_, index) => `${index + 1}`),
              datasets: [
                {
                  label: names[0],
                  data: scores.map((frame) => frame[0]),
                  borderColor: 'rgb(0, 0, 255)',
                  backgroundColor: 'rgba(0, 0, 255, 0.5)',
                },
                {
                  label: names[1],
                  data: scores.map((frame) => frame[1]),
                  borderColor: 'rgb(255, 0, 0)',
                  backgroundColor: 'rgba(255, 0, 0, 0.5)',
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGraphClose}>Close</Button>
        </DialogActions>
      </Dialog>
      </div>
    </div>
  );
};

export default VolleyballScoreApp;